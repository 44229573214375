<template>
  <div class="pucontainer">
    <!-- 会员中心 -->
    <div class="member_nav">
      <div class="base">
        <div class="b_right">
          <div class="brand blod">{{ info.username }}</div>
          <div class="integral">
            <span class="blod">{{ formatIntegral(info.integral) }}</span>&nbsp;{{ $t("vip.points") }}
            <span class="bor">|</span>
            <span class="soon">{{ formatIntegral(info.waitintegral) }}</span>&nbsp;{{ $t("vip.waiting_points") }}
          </div>
          <div class="integral" style="text-transform: uppercase;">
            <span class="blod">{{ (info.abalance) }}</span>&nbsp;{{ $t("vip.abalance") }}
            <span class="bor">|</span>
            <span class="soon">{{ (info.wbalance) }}</span>&nbsp;{{ $t("vip.wbalance") }}
          </div>
          <div class="label blod">
            {{
              $i18n.locale == "en" ? info.Frontnameen : info.Frontname
            }}&nbsp;{{ $t("vip.rank") }}
          </div>
        </div>
      </div>
      <q-list separator>
        <q-item clickable v-ripple v-for="(item, index) in navarr" :key="index" :to="item.url">
          <q-item-section>{{ item.name }}</q-item-section>
          <q-item-section side>
            <q-icon name="iconfont icon-jiantou1" size="14px" />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="membercon">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { memberInit } from '@/api/my'
import { formatIntegral } from '@/utils/filterData.js'
import store from '@/store'

export default {
  data() {
    return {
      info: '',
      navarr: [
        { name: this.$t('vip.my_points'), url: '/my/points' },
        { name: this.$t('vip.my_balance'), url: '/my/balance' },
        { name: this.$t('vip.my_rewards'), url: '/my/prize' },
        { name: this.$t('vip.points_changes'), url: '/my/exchange' },
        { name: this.$t('vip.questions'), url: '' }
      ]
    }
  },
  watch: {
    '$route'(to) {
      if (to.path === '/my/balance' || to.path === '/my/points') {
        this.getVipInfo()
      }
    }
  },
  methods: {
    async getVipInfo() {
      const res = await memberInit()
      this.info = res
      store.commit('setMemberInfo', res)
    }
  },
  mounted() {
    this.getVipInfo()
  },
  setup() {
    return {
      formatIntegral
    }
  }
}
</script>

<style lang="scss" scoped>
.q-item {
  padding: 8px 5px;
}

.pucontainer {
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;

  .membercon {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 160px;
  }
}

.member_nav {
  width: 18%;
  position: absolute;
  padding-left: 5px;

  .base {
    margin-bottom: 10px;

    .b_right {
      padding-left: 5px;

      .integral {
        .bor {
          display: inline-block;
          margin: 0 5px;
          margin-top: -1px;
        }
      }

      .label {
        text-transform: uppercase;
      }
    }
  }
}
</style>
